import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import Logo from "../images/fettl_logo-wbl.svg"

const IndexPage = () => (
  <Layout>
    <SEO title="Coming soon" />

    <div class={"centered"}>
      <h1 style={{ display: "none" }}>Fettl</h1>
      <img src={Logo} alt="Fettl" />
      <p>
        We're working hard on getting our services just right. Check back soon!
      </p>
    </div>
  </Layout>
)

export default IndexPage
